@import '../../base/base';
  /*
      ==================
          Total Sales
      ==================
  */
  // body.dark {
    .widget-two {
      position: relative;
      background: #fff;
      padding: 0;
      border-radius: 6px;
      height: 100%;
      box-shadow: none;
      border: 1px solid #e0e6ed;
      box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
    
      .widget-content {
        font-size: 17px;
      }
    
      .w-chart {
        position: absolute;
        bottom: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    
      .w-numeric-value {
        display: flex;
        color: #fff;
        font-weight: 500;
        padding: 20px;
        justify-content: space-between;
    
        .w-icon {
          display: inline-block;
          background: #fcf5e9;
          padding: 13px 12px;
          border-radius: 50%;
          display: inline-flex;
          align-self: center;
          height: 45px;
          width: 45px;
        }
    
        svg {
          display: block;
          color: $warning;
          width: 20px;
          height: 20px;
        }
    
        .w-value {
          margin-bottom: -9px;
          letter-spacing: 0px;
          font-size: 19px;
          display: block;
          color: #0e1726;
          font-weight: 600;
        }
    
        .w-numeric-title {
          font-size: 13px;
          color: #888ea8;
          font-weight: 600;
        }
      }
    }
// }


@media (max-width: 575px) {
    /*
        ==================
            Total Sales
        ==================
    */
    // body.dark {
        .widget-two .w-chart {
        position: inherit;
        }
    // }
    
  }