@import '../../base/base';
  /*
      ==================
          Widget
      ==================
  */
  // body.dark {
    .widget-one {
      position: relative;
      padding: 0;
      border-radius: 6px;
      border: none;
      background-color: #fff;
      border: 1px solid #e0e6ed;
      box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
    
      .widget-content {
        font-size: 17px;
      }
    
      .w-numeric-value {
        position: absolute;
        display: flex;
        color: #fff;
        font-weight: 500;
        padding: 20px;
        width: 100%;
        justify-content: space-between;
    
        .w-icon {
          display: inline-block;
          background: #ddf5f0;
          padding: 13px 12px;
          border-radius: 12px;
          display: inline-flex;
          align-self: center;
          height: 45px;
          width: 45px;
          margin-right: 14px;
        }
    
        svg {
          display: block;
          color: #009688;
          width: 20px;
          height: 20px;
          fill: rgba(26, 188, 156, 0.49);
        }
    
        .w-value {
          font-size: 26px;
          display: block;
          color: #515365;
          font-weight: 600;
          margin-bottom: -9px;
          text-align: right;
        }
    
        .w-numeric-title {
          font-size: 13px;
          color: #515365;
          letter-spacing: 1px;
          font-weight: 600;
        }
      }
    
      .apexcharts-canvas svg {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  // }