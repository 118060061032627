@import '../../base/base';
// body.dark {
    .layout-spacing {
      padding-bottom: 25px;
    }
    
    .widget {
      position: relative;
      padding: 20px;
      border-radius: 6px;
      border: none;
      background: #fff;
      border: 1px solid #e0e6ed;
      box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
    
      .widget-heading {
        margin-bottom: 15px;
      }
    
      h5 {
        letter-spacing: 0px;
        font-size: 19px;
        display: block;
        color: #0e1726;
        font-weight: 600;
        margin-bottom: 0;
      }
    
      .widget-content {}
    }
    
    .apexcharts-legend-text {
      color: #3b3f5c !important;
    }
    
    .apexcharts-tooltip.apexcharts-theme-dark {
      background: #191e3a !important;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    
      .apexcharts-tooltip-title {
        background: #191e3a !important;
        border-bottom: 1px solid #191e3a;
      }
    }
  // }